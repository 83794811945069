import React from 'react';
import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import Header from '../../components/header';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import { useStyles } from '../../utils/tu-instituto/styles';
import { useGlobalStyles } from '../../utils/styles';

const Historial = () => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  return (
    <Layout>
      <SEO title="Historia" />

      <div className={globalClasses.container}>
        <Header title="Evolución histórica del ISSET"></Header>

        <Grid container spacing={3} className={classes.info}>
          <Grid item xs={12}>
            <Typography variant="body1">El actual Instituto de Seguridad Social del Estado de Tabasco, fue creado por el Gobierno del Estado de Tabasco en el año de 1960 con el nombre de Dirección de Pensiones Civiles del Estado, como un organismo descentralizado con personalidad jurídica propia. Como parte de los servicios que otorgaba, en 1965 el organismo empezó a conceder préstamos hipotecarios.</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">En noviembre de 1974 se expide la Ley de Pensiones Civiles del Estado, la cual fue derogada el 13 de diciembre de 1980 por la Ley del Instituto de Seguridad Social del Estado de Tabasco.</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">En diciembre de 1980, mediante decreto No. 2016 publicado en el suplemento No. 3994 del Periódico Oficial del Estado, se constituyó el Instituto de Seguridad Social del Estado de Tabasco.</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">En enero de 1982, mediante un convenio con el Hospital Juan Graham Casasús, el ISSET contó con 30 camas para atención hospitalaria, utilizando personal e infraestructura del inmueble. Se inician subrogaciones a los 16 municipios, atendiendo solicitudes médicas necesarias para la población derechohabiente: odontología, atención médica y laboratorio, realizando acuerdos con la entonces Secretaría de Salubridad Pública para la atención de urgencias.</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">En marzo de 1983, se inaugura el policlínico ISSET que otorgó servicios de consulta externa, cirugía menor y urgencias, y para mayo de 1984 empieza a funcionar el Hospital de Concentración de los Servicios Médicos “Centro Médico ISSET, Dr. Julián A. Manzur”.</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">En agosto de 1984, se expide la Ley del Instituto de Seguridad Social del Estado de Tabasco, mediante el decreto 0269 publicado en el Periódico Oficial del Estado No. 4371. Esta nueva Ley contempla otras prestaciones sociales para los servidores públicos, como seguro de retiro, y aumento de otras como los seguros de vida para pagos de funerales y prestaciones económicas.</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">En 1999, se transforma la naturaleza jurídica del Instituto de Seguridad Social del Estado de Tabasco, por un organismo público desconcentrado, adscrito a la Secretaría de Administración y Finanzas, teniendo por objeto proporcionar seguridad social a los servidores públicos de los poderes del Estado, municipios, organismos descentralizados, empresas de participación estatal y en general con quienes convengan con el Instituto, mediante el decreto No. 200, publicado en el suplemento No. 6924, del Periódico Oficial del Estado.</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">La Ley del Instituto de Seguridad Social del Estado de Tabasco es sujeta de otras modificaciones, el 12 de diciembre de 1987, 2 de enero de 1991 y 16 de junio de 1999.</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">En diciembre de 2015, se expide la nueva Ley de Seguridad Social del Estado de Tabasco, mediante decreto 294, publicada en el Periódico Oficial del Estado. Esta nueva Ley contempla la creación del Instituto de Seguridad Social del Estado de Tabasco, como un Organismo Público Descentralizado del Poder Ejecutivo del Estado de Tabasco, dotado de personalidad jurídica y patrimonio propio. Sectorizado a la Secretaría de Planeación y Finanzas.</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">La última reforma a la Ley de Seguridad Social del Estado de Tabasco, se realiza mediante decreto 089 de fecha 11 de mayo de 2017. Posteriormente a través del decreto número 7808 de fecha 05 de Julio de 2017, publicado en el Periódico Oficial del Estado, únicamente se reforma el artículo 135, que a la letra cita:</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography component="blockquote">
              “Artículo 135.- El responsable del Ente Público que no entere los descuentos que procedan a favor de ISSET en términos de la LSSET, será acreedor a una sanción económica de 100 a 150 veces el valor diario de la Unidad de Medida y Actualización, independientemente de la responsabilidad laboral, civil, administrativa o penal en que incurra. Lo anterior sin perjuicio de regularizar la situación en los términos que correspondan”.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">En la actualidad mantiene una personalidad jurídica descentralizada y patrimonio propio.</Typography>
          </Grid>
        </Grid>
      </div>
    </Layout>
  );
};

export default Historial;